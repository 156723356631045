import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Loader from '../../../common/Loader'
import { IPatientInfos } from '../../reducers'
import dateformat from 'dateformat'

interface IProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  updateHistory: (patientId: number, history: number[]) => Promise<unknown>
  patientInfo: IPatientInfos
  updatePatient: (patient: IPatientInfos) => Promise<unknown>
}

const ModalHistory = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedHistory, setSelectedHistory] = useState<Array<number>>([])
  const [selectedAF, setSelectedAF] = useState<number>(0)
  const [fevKnown, setFevKnown] = useState<boolean>()
  const [fev, setFev] = useState<number>()
  const [historyComment, setHistoryComment] = useState('')

  useEffect(() => {
    setIsLoading(true)
    const histTemp: number[] = []
    props.patientInfo?.historyList &&
      props.patientInfo.historyList.forEach((hist) => {
        hist.historyTypeId === 18 ||
        hist.historyTypeId === 13 ||
        hist.historyTypeId === 14
          ? setSelectedAF(hist.historyTypeId)
          : histTemp.push(hist.historyTypeId)
      })
    setSelectedHistory(histTemp)
    setHistoryComment(
      props.patientInfo?.historyComment
        ? props.patientInfo?.historyComment
        : '',
    )
    setIsLoading(false)
    if (
      props.patientInfo &&
      props.patientInfo.fev?.toString() &&
      props.patientInfo.fev.toString() !== '-1'
    ) {
      setFevKnown(true)
      setFev(props.patientInfo.fev)
    } else {
      setFevKnown(false)
      setFev(0)
    }
  }, [props.patientInfo]) //eslint-disable-line

  useEffect(() => {
    if (fev) {
      if (fev > 100) setFev(100)
      if (fev < -1) setFev(0)
    }
  }, [fev])

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      selectedAF !== 0
        ? await props.updateHistory(props.patientInfo.id, [
            ...selectedHistory,
            selectedAF,
          ])
        : await props.updateHistory(props.patientInfo.id, [...selectedHistory])
      const historyList: any[] = []
      selectedAF !== 0
        ? [...selectedHistory, selectedAF].forEach((h) =>
            historyList.push({
              historyTypeId: h,
              patientId: props.patientInfo.id,
            }),
          )
        : [...selectedHistory].forEach((h) =>
            historyList.push({
              historyTypeId: h,
              patientId: props.patientInfo.id,
            }),
          )
      await props.updatePatient({
        ...props.patientInfo,
        historyList,
        historyComment,
        fev: fevKnown ? fev : -1,
        birthDay: dateformat(
          new Date(props.patientInfo.birthDay),
          'yyyy-mm-dd',
        ),
        countryId: 1,
      })
      toast.success('Modifications effectuées')
      props.setIsOpen(false)
    } catch {
      toast.error("Erreur lors de l'ajout de l'antécédent")
    } finally {
      setIsLoading(false)
    }
  }

  const handleCheck = (id: number) => {
    if (id === 1 && selectedHistory.includes(1)) setSelectedAF(0)
    if (id === 12) {
      setSelectedAF(0)
      setSelectedHistory([12])
    } else
      selectedHistory.includes(id)
        ? setSelectedHistory(
            [...selectedHistory].filter((h) => h !== id && h !== 12),
          )
        : setSelectedHistory([...selectedHistory.filter((h) => h !== 12), id])
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      aria-labelledby="responsive-dialog-title"
      sx={{ padding: '30px auto', height: '100%' }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <Loader text="Enregistrement de l'antécédent" open={isLoading} />
      <DialogTitle
        id="responsive-dialog-title"
        sx={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
          color: 'white',
          fontWeight: '700',
          fontSize: '24px',
        }}
      >
        {'Ajouter un antécédent'}
        <IconButton
          sx={{ position: 'absolute', right: '20px' }}
          onClick={() => props.setIsOpen(false)}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          width: '100%',
          height: '40%',
          padding: '0 40px',
          margin: '20px 0',
        }}
      >
        <Box
          sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}
        >
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedHistory.includes(1)}
            label="Fibrillation atriale"
            onChange={() => handleCheck(1)}
          />
          {selectedHistory.includes(1) && (
            <RadioGroup
              sx={{ marginLeft: '30px' }}
              onChange={(e) => setSelectedAF(parseInt(e.target.value))}
              value={selectedAF}
            >
              <FormControlLabel
                value={18}
                control={
                  <Radio
                    sx={{
                      color: selectedAF === 18 ? '#84c2ce !important' : '',
                    }}
                  />
                }
                label="paroxystique"
              />
              <FormControlLabel
                value={13}
                control={
                  <Radio
                    sx={{
                      color: selectedAF === 13 ? '#84c2ce !important' : '',
                    }}
                  />
                }
                label="persitante"
              />
              <FormControlLabel
                value={14}
                control={
                  <Radio
                    sx={{
                      color: selectedAF === 14 ? '#84c2ce !important' : '',
                    }}
                  />
                }
                label="permanente"
              />
            </RadioGroup>
          )}
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedHistory.includes(2)}
            label="Tachycardie Atriale"
            onChange={() => handleCheck(2)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedHistory.includes(3)}
            label="Flutter Atrial"
            onChange={() => handleCheck(3)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedHistory.includes(4)}
            label="Tachycardie Ventriculaire"
            onChange={() => handleCheck(4)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedHistory.includes(5)}
            label="Fibrillation Ventriculaire"
            onChange={() => handleCheck(5)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedHistory.includes(6)}
            label="Coronaropathie"
            onChange={() => handleCheck(6)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedHistory.includes(7)}
            label="Cardiopathie dilatée"
            onChange={() => handleCheck(7)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedHistory.includes(8)}
            label="Hypertension artérielle"
            onChange={() => handleCheck(8)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedHistory.includes(9)}
            label="Diabète"
            onChange={() => handleCheck(9)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedHistory.includes(11)}
            label="Stimulo-dépendance"
            onChange={() => handleCheck(11)}
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={selectedHistory.includes(12)}
            label={<span style={{ fontWeight: 'bold' }}>Aucun antécédent</span>}
            onChange={() => handleCheck(12)}
          />

          <FormControlLabel
            control={<Checkbox />}
            checked={fevKnown}
            label="Fraction d’Ejection du Ventricule Gauche connue"
            sx={{ marginTop: '30px' }}
            onChange={() => setFevKnown(!fevKnown)}
          />
          {fevKnown && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input
                value={fev}
                type="number"
                onChange={(e) => setFev(parseInt(e.target.value))}
                min={0}
                max={100}
                style={{
                  fontFamily: 'Roboto',
                  border: '1px solid #84c2ce',
                  borderRadius: '8px',
                  fontSize: '14px',
                  padding: '5px',
                }}
              />
              <span style={{ marginLeft: '5px' }}>en %</span>
            </Box>
          )}

          <Box
            sx={{
              marginTop: '30px',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <span>Commentaires / autres antécédents:</span>
            <textarea
              style={{
                marginTop: '10px',
                border: '1px solid #84c2ce',
                borderRadius: '8px',
                padding: '10px',
                fontFamily: 'Roboto',
                fontSize: '14px',
              }}
              value={historyComment}
              onChange={(e) => setHistoryComment(e.target.value)}
              maxLength={255}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '15px',
            }}
          >
            <IconButton onClick={handleSubmit} sx={{ alignSelf: 'right' }}>
              <SaveIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalHistory
