import { PropsFromReduxCenter } from '../containers/CenterContainer'
import {
  DataGridPro,
  GridColumns,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import { useEffect, useRef, useState } from 'react'
import { Toolbar } from './Table/Toolbar'
import Loader from '../../common/Loader'
import { Box, IconButton, Tab, Tabs } from '@mui/material'
import { toast } from 'react-toastify'
import PaginationComponent from '../../../modules/common/PaginationComponent'
import { getCenterType } from '../../../utils/addPatient'
import { useSelector } from 'react-redux'
import { canAddModifyDeleteCenter } from '../../auth/selectors/authSelector'
import CenterDetailsModal from './Modals/CenterDetailsModal'
import { CenterDTO } from 'src/helpers/dto'
import EditIcon from '@mui/icons-material/Edit'
import SettingsIcon from '@mui/icons-material/Settings'
import AddNewCenterModal from './Modals/AddNewCenterModal'
import { useLocation } from 'react-router-dom'

const CenterComponent = (props: PropsFromReduxCenter) => {
  const location = useLocation()
  const q = useRef(new URLSearchParams(location.search)).current

  const [tab, setTab] = useState<0 | 1>(0)
  const [activePage, setActivePage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(
    q.has('limit')
      ? parseInt(q.get('limit') as string)
      : parseInt(localStorage.getItem('rowsPerPage') as string),
  )
  const [searchText, setSearchText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedCenter, setSelectedCenter] = useState<CenterDTO | null>(null)
  const [isEditCenterOpen, setIsEditCenterOpen] = useState(false)

  const handleRowClick = (center: CenterDTO) => {
    setSelectedCenter(center)
    setIsModalOpen(true)
  }

  const fetchDatas = async (loader: boolean) => {
    if (loader) setIsLoading(true)
    try {
      await props.getCompany(rowsPerPage, activePage - 1, searchText, tab === 1)
    } catch {
      toast.error('Impossible de charger les centres')
    } finally {
      setIsLoading(false)
    }
  }

  const fetchDisplay = async () => {
    setIsLoading(true)
    try {
      await props.getCompaniesSummary()
    } catch {
      toast.error('Impossible de charger les centres')
    } finally {
      setIsLoading(false)
    }
  }

  const hasPermissionToCreateCenter = useSelector(canAddModifyDeleteCenter)

  useEffect(() => {
    fetchDisplay()
  }, []) //eslint-disable-line

  useEffect(() => {
    fetchDatas(true)
  }, [rowsPerPage, activePage, tab]) //eslint-disable-line

  useEffect(() => {
    fetchDatas(false)
  }, [searchText]) //eslint-disable-line

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const columns: GridColumns = [
    {
      field: 'col1',
      headerName: 'Profil',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => {
        const type =
          params.row.centerDetails?.centerType ||
          params.row.centerDetails?.center_type

        return `${type ? getCenterType(type) : ''}`
      },
    },
    {
      field: 'col2',
      headerName: 'Nom',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.userName ? params.row.userName : ''} ${
          params.row.companyName ? params.row.companyName : ''
        }`,
    },
    {
      field: 'col3',
      headerName: 'Ville',
      flex: 1,
      minWidth: 250,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.city ? params.row.city : ''} `,
    },
    {
      field: 'col4',
      headerName: 'Téléphone',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.phone ? params.row.phone : ''}`,
    },
    {
      field: 'col5',
      headerName: 'Code postal',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.zipCode ? params.row.zipCode : ''}`,
    },
    {
      field: 'col6',
      headerName: 'FINESS',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params.row.centerDetails?.finess
            ? params.row.centerDetails?.finess
            : ''
        }`,
    },
    {
      field: 'col7',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <>
          {hasPermissionToCreateCenter && (
            <IconButton
              color="primary"
              onClick={() => {
                setSelectedCenter(params.row)
                setIsEditCenterOpen(true)
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              handleRowClick(params.row)
            }}
          >
            <SettingsIcon />
          </IconButton>
        </>
      ),
    },
  ]

  return (
    <Box sx={{ height: 'calc(100vh - 240px)', width: '100%' }}>
      <Loader open={isLoading} text="Chargement des centres" />
      <Tabs
        TabIndicatorProps={{
          style: {
            backgroundColor: '#ffa726',
            display: 'flexWrap',
          },
        }}
        onChange={(_, newTab) => {
          setActivePage(1)
          setTab(newTab)
        }}
        textColor="primary"
        value={tab}
      >
        <Tab
          label={`Tous les centres : ${
            props.centersSummary && props.centersSummary
              ? props.centersSummary.centersCount +
                props.centersSummary.implantCentersCount
              : 0
          }`}
          {...a11yProps(0)}
          wrapped={false}
        />
        <Tab
          label={`Centres implanteurs : ${
            props.centersSummary?.implantCentersCount
              ? props.centersSummary.implantCentersCount
              : 0
          }`}
          {...a11yProps(0)}
          wrapped={false}
        />
      </Tabs>

      <DataGridPro
        rows={props.centers.data ? props.centers.data : []}
        columns={columns}
        components={{
          Toolbar,
        }}
        componentsProps={{
          toolbar: {
            title: 'Centres',
            onRefresh: fetchDatas,
            searchText,
            setSearchText,
            canCreateCenter: hasPermissionToCreateCenter,
          },
        }}
        rowHeight={60}
        disableColumnMenu
        hideFooter
        disableSelectionOnClick
        sx={{
          border: 'none',
          padding: '3px 15px',
        }}
      />
      <PaginationComponent
        pages={props.centers.totalPages}
        activePage={activePage}
        setActivePage={setActivePage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      {isModalOpen && selectedCenter && (
        <CenterDetailsModal
          hasPermissionToManageMakerKey={hasPermissionToCreateCenter}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          center={selectedCenter}
        />
      )}
      {isEditCenterOpen && selectedCenter && (
        <AddNewCenterModal
          open={isEditCenterOpen}
          setOpen={setIsEditCenterOpen}
          editingCenter={selectedCenter}
        />
      )}
    </Box>
  )
}

export default CenterComponent
