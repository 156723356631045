import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
  DataGridPro,
  GridColumns,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import { IDoctor } from '../reducers'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { toast } from 'react-toastify'
import Loader from '../../common/Loader'
import PaginationComponent from '../../../modules/common/PaginationComponent'
import { CustomTooltip } from './../../../utils/CustomTooltip'
import { ToolbarInput } from './../../../utils/ToolbarInput'
import {
  DoctorType,
  IFoundDoctor,
  INewDoctorAddress,
  INewDoctorInfos,
  INewDoctorStep1,
} from '../../doctors/reducers'
import { getDoctorType } from '../../../utils/addPatient'
import { PersonAdd } from '@mui/icons-material'
import AddNewDoctorModal from '../../doctors/components/AddNewDoctorModal'

interface IProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleDoctorSelection: (doctor: any) => void
  getDoctors: (
    size: number,
    page: number,
    doctorType: string,
    search: string,
    companyId?: number,
  ) => Promise<unknown>
  doctorType: DoctorType
  getDoctorAutocomplete?: (
    name: string,
    page: number,
    limit: number,
  ) => Promise<IFoundDoctor[]>
  getCountries?: () => Promise<unknown>
  addDoctor?: {
    step1: (doctor: INewDoctorStep1) => Promise<number>
    step2: (id: number) => Promise<unknown>
    step3: (id: number, address: INewDoctorAddress) => Promise<unknown>
    step4: (id: number, doctorInfos: INewDoctorInfos) => Promise<unknown>
  }
  companyId?: number
}

const SearchDoctorModal = (props: IProps) => {
  const doctors: IDoctor[] = useSelector(
    (state: RootState) => state.doctorsReducer.doctors.data,
  )
  const totalPages: number = useSelector(
    (state: RootState) => state.doctorsReducer.doctors.totalPages,
  )
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState(
    parseInt(localStorage.getItem('rowsPerPage') as string),
  )
  const [activePage, setActivePage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [openAddDoctor, setOpenAddDoctor] = useState(false)

  // useEffect(() => {
  //     console.log(props.doctorType, props.getDoctorAutocomplete, props.getCountries, props.addDoctor)
  // }, [props.doctorType, props.getDoctorAutocomplete, props.getCountries, props.addDoctor])

  const compId =
    props.doctorType === 'Implant' || props.doctorType === 'Billing'
      ? props.companyId
      : undefined
  useEffect(() => {
    setLoading(true)
    props
      .getDoctors(rows, activePage - 1, props.doctorType, searchText, compId)
      .then(() => setLoading(false))
      .catch(() => toast.error('Impossible de charger les médecins'))
  }, [rows, activePage]) //eslint-disable-line

  useEffect(() => {
    if (openAddDoctor) {
      setLoading(false)
      return
    }
    setActivePage(1)
    props
      .getDoctors(rows, activePage - 1, props.doctorType, searchText, compId)
      .then(() => setLoading(false))
      .catch(() => toast.error('Impossible de charger les médecins'))
  }, [searchText, props.doctorType, openAddDoctor]) //eslint-disable-line

  const handleClose = () => {
    props.setIsOpen(false)
  }

  const columns: GridColumns = [
    {
      field: 'col1',
      headerName: 'Fonction',
      flex: 0.8,
      renderCell: (params) =>
        params.row.doctor ? (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', padding: '7px 0' }}
          >
            {getDoctorType(params.row.doctor).map(
              (type: string, index: number) => {
                return (
                  <span key={index} style={{ margin: '7px 0' }}>
                    {type}
                  </span>
                )
              },
            )}
          </Box>
        ) : (
          ''
        ),
    },
    {
      field: 'col2',
      headerName: 'Nom\nPrénom',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.firstName ? params.row.firstName : ''} ${params.row.userName ? params.row.userName : ''}`,
    },
    {
      field: 'col3',
      headerName: 'Adresse',
      flex: 1.2,
      renderCell: (params) => (
        <Box sx={{ padding: '15px 0', minHeight: '52px' }}>
          {params.row.addresses &&
            params.row.addresses.map((address: any, index: number) => (
              <CustomTooltip
                key={index}
                text={
                  <p
                    style={{ margin: 0, fontSize: '13px' }}
                  >{`${address.street ? address.street : ''} ${address.zipCode ? address.zipCode : ''} ${address.city ? address.city : ''}`}</p>
                }
              >
                <Box sx={{ margin: '5px 0' }}>
                  {`${address.street ? address.street : ''} ${address.zipCode ? address.zipCode : ''} ${address.city ? address.city : ''}`}
                </Box>
              </CustomTooltip>
            ))}
        </Box>
      ),
    },
    {
      field: 'col4',
      headerName: 'Téléphone',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.phone ? params.row.phone : ''}`,
    },
    {
      field: 'col5',
      headerName: 'Email',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.mail ? params.row.mail : ''}`,
    },
  ]

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{ padding: '30px auto' }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
            color: 'white',
            fontWeight: '700',
            fontSize: '24px',
          }}
        >
          {props.doctorType === 'Billing' &&
            'Veuillez choisir un cardiologue facturant la télésurveillance'}
          {props.doctorType === 'Implant' &&
            'Veuillez choisir un cardiologue implanteur'}
          {props.doctorType === 'Cardiolog' &&
            'Veuillez choisir un cardiologue traitant'}
          {props.doctorType === 'FamilyDoctor' &&
            'Veuillez choisir un médecin traitant'}
          <IconButton
            sx={{ position: 'absolute', right: '20px' }}
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ padding: '20px 30px', height: 'calc(100vh - 190px)' }}
        >
          <Box
            sx={{
              padding: '10px',
              marginTop: '10px',
              display: 'flex',
              justifyContent: 'end',
              background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
              borderRadius: '15px 15px 0px 0px',
            }}
          >
            {(props.doctorType === 'FamilyDoctor' ||
              props.doctorType === 'Cardiolog') &&
              props.getCountries &&
              props.addDoctor && (
                <Tooltip
                  title={'Inscrire un médecin'}
                  style={{ marginRight: '5px' }}
                >
                  <IconButton onClick={() => setOpenAddDoctor(true)}>
                    <PersonAdd />
                  </IconButton>
                </Tooltip>
              )}
            <ToolbarInput
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </Box>
          <Box sx={{ height: 'calc(100% - 140px)', width: '100%' }}>
            <Loader open={loading} text="Chargement des médecins" />
            <DataGridPro
              rows={doctors}
              columns={columns}
              onRowClick={props.handleDoctorSelection}
              pageSize={rows}
              getRowHeight={() => 'auto'}
              hideFooter
              disableColumnMenu
              disableSelectionOnClick
              sx={{
                border: 'none',
                cursor: 'pointer',
              }}
            />
          </Box>
          <PaginationComponent
            pages={totalPages}
            activePage={activePage}
            setActivePage={setActivePage}
            rowsPerPage={rows}
            setRowsPerPage={setRows}
          />

          {(props.doctorType === 'FamilyDoctor' ||
            props.doctorType === 'Cardiolog') &&
            props.getDoctorAutocomplete &&
            props.getCountries &&
            props.addDoctor && (
              <AddNewDoctorModal
                isOpen={openAddDoctor}
                setIsOpen={setOpenAddDoctor}
                getDoctorAutocomplete={props.getDoctorAutocomplete}
                getCountries={props.getCountries}
                addDoctor={props.addDoctor}
              />
            )}
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default SearchDoctorModal
