import { PropsFromReduxBilling } from '../containers/BillingContainer'
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import { useEffect, useRef, useState } from 'react'
import { Toolbar } from './Table/Toolbar'
import Loader from '../../common/Loader'
import { Box, Button, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import history from '../../../routes/history'
import { STELLAIR_FSE } from '../../../helpers/env'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { BillingStatus, IBill, IBillingDetails } from '../reducers'
import {
  BsChevronRight,
  BsFillCalendarEventFill,
  BsFillInfoCircleFill,
} from 'react-icons/bs'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { AiFillEye, AiOutlineEuroCircle } from 'react-icons/ai'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material'
import PaginationComponent from '../../common/PaginationComponent'
import { HtmlTooltip } from '../../../utils/CustomTooltip'
import { ILastBilling } from '../../auth/reducers'
import { BillStatus } from '../services'
import { capitalize, createSearchParams } from '../../../utils/utils'
import { companyIconsNameMap } from '../../../utils/constants'
import { canViewPatientData } from '../../auth/selectors/authSelector'
import { useSelector } from 'react-redux'

const BillingComponent = (props: PropsFromReduxBilling) => {
  const location = useLocation()
  const q = useRef(new URLSearchParams(location.search)).current

  const [data, setData] = useState([])
  const [activePage, setActivePage] = useState(
    q.has('page') ? parseInt(q.get('page') as string) : 1,
  )
  const [rowsPerPage, setRowsPerPage] = useState(
    q.has('limit')
      ? parseInt(q.get('limit') as string)
      : parseInt(localStorage.getItem('rowsPerPage') as string),
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [billingDetails, setBillingDetails] = useState<
    (IBillingDetails & { billingDate: Date }) | null
  >(null)
  const [activeTab, setActiveTab] = useState(
    q.has('activeTab') ? parseInt(q.get('activeTab') as string) : 0,
  )

  const prosthesisGammeName = (value: string) => {
    switch (value) {
      case 'prosthesis.gamme.defib.cardiac':
        return 'Défibrillateur'
      case 'prosthesis.gamme.monit.implant':
        return 'Moniteur'
      case 'prosthesis.gamme.stim.cardiac':
        return 'Stimulateur'
      case 'prosthesis.gamme.null' || null:
        return 'Gamme non définie'
    }
  }

  useEffect(() => {
    props.getBillablePatient(1, 20, getBillStatus())
    props.getBillableSummary()
  }, [activeTab])

  useEffect(() => {
    if (props.billablePatient) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [props.billablePatient])

  useEffect(() => {
    !loading ? setData(props.billablePatient.data) : setData([])
  }, [loading, props.billablePatient]) //eslint-disable-line

  function getBillStatus() {
    switch (activeTab) {
      case 0:
        return BillStatus.TO_BILL
      case 1:
        return BillStatus.PENDING
      case 2:
        return BillStatus.REJECTED
      default:
        return BillStatus.TO_BILL
    }
  }

  function getBillStatusTitle(
    bill: IBill & { billing_detail: IBillingDetails },
  ): string {
    if (bill.billing_detail) {
      return bill.billing_detail.etat_facture
    }
    if (!bill.status) {
      return 'Facture papier'
    }
    return 'Non facturé'
  }

  useEffect(() => {
    props.getBillablePatient(activePage, rowsPerPage, getBillStatus())

    const obj: { [key: string]: string | string[] } = {
      page: activePage.toString() || '',
      limit: rowsPerPage.toString() || '',
      activeTab: activeTab.toString() || '',
    }

    const params = createSearchParams(obj)

    const q = params.toString()

    if (history.location.search !== q) {
      history.push({
        search: q,
      })
    }
  }, [rowsPerPage, activePage, activeTab]) //eslint-disable-line

  const handlePatientInfoByID = (patientId: number) => {
    history.push(`/patients/${patientId}`)
  }

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setData([])
    setActiveTab(newValue)
    setActivePage(1)
  }

  const handleCreateBill = async (row: any) => {
    await new Promise<IBill>((resolve, reject) => {
      props
        .createBill(row.id)
        .then((res) => {
          return resolve(res as IBill)
        })
        .catch((err) => {
          return reject(err)
        })
    })

    if (row.billingDetails) return
    const replaceSpacesWithHyphens = (str: string) => {
      return str.replace(/\s+/g, '-')
    }
    const billId = row.id
    const birthDate = moment(row.birthDay).format('DD/MM/YYYY')
    let billDate: string = moment(row.billableDate).format('DD/MM/YYYY')
    if (row.code === 'TVA' && row.actNb > 1) {
      for (let index = 1; index < row.actNb; index++) {
        billDate = billDate.concat(
          `,${moment(row.billableDate).subtract(index, 'month').format('DD/MM/YYYY')}`,
        )
      }
    }
    console.log(`billDate ${billDate}`)
    const ssn = row.socialInsuranceNumber?.replace(/\s/g, '') || ''

    const sexMap: { [key: string]: string } = {
      M: 'MASCULIN',
      F: 'FEMININ',
    }
    const sexe = sexMap[row.gender as keyof typeof sexMap] || ''

    const prenom = replaceSpacesWithHyphens(row.firstName)
    const nom = replaceSpacesWithHyphens(row.patientName)

    const URL = `${STELLAIR_FSE}/fse/?ticket_id=${billId}&prenom=${prenom}&nom=${nom}&nir=${ssn}&dateNaissance=${birthDate}&sexe=${sexe}&dateSoins=${billDate}&rangNaissance=&regime=&caisse=&centre=&lireVitale=false&sso=cognito`
    window.open(URL, '_blank')

    console.log('URL Stellair: ', URL)
  }

  const getNotBillableReason = (row: any): string[] => {
    const reasons: string[] = []
    if (!row.patientName) {
      reasons.push('Nom du patient manquant')
    }
    if (!row.firstName) {
      reasons.push('Prénom du patient manquant')
    }
    if (!row.birthDay) {
      reasons.push('Date de naissance du patient manquante')
    }
    if (
      row.prosthesisBillable?.length === 0 ||
      (row.prosthesisBillable && !row.prosthesisBillable[0]?.amount)
    ) {
      reasons.push('Prothèse non facturable')
    }

    return reasons
  }

  function getBillStatusColor(status: any) {
    switch (status) {
      case 'EN_ANOMALIE':
      case 'EN_REJET_TECHNIQUE':
      case 'EN_REJET_RSP':
      case 'REJET_RSP_ACQUITTE':
      case 'ABANDONEE':
        return '#ff6b6b'

      case 'PAYEE':
      case 'ENCAISSEE':
      case 'EN_ANOMALIE_ENCAISSEE':
        return '#84cc90'

      default:
        return 'gray'
    }
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const canViewPatientInfos = useSelector(canViewPatientData)

  const columns: GridColumns = [
    ...(canViewPatientInfos
      ? [
          {
            field: 'col1',
            headerName: 'Nom',
            width: 200,
            sortable: false,
            valueGetter: (params: GridValueGetterParams) =>
              `${params.row.patientName?.toUpperCase()}`,
          },
          {
            field: 'col2',
            headerName: 'Prénom',
            width: 200,
            sortable: false,
            valueGetter: (params: GridValueGetterParams) =>
              `${capitalize(params.row?.firstName)}`,
          },
        ]
      : []),
    {
      field: 'col3',
      headerName: `Date d'implantation`,
      width: 150,
      minWidth: 250,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${moment(params.row.implantDate).format('DD/MM/YYYY')} `,
    },
    {
      field: 'col4',
      headerName: 'Prothèse',
      width: 175,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params.row.prosthesisGamme
            ? prosthesisGammeName(params.row.prosthesisGamme)
            : ''
        }`,
    },
    {
      field: 'col5',
      width: 175,
      headerName: 'Marque',
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        params.row.maker ? companyIconsNameMap[params.row.maker] : <></>,
    },
    {
      field: 'col6',
      headerName: 'Date de dernière transmission',
      width: 250,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params.row.lastTransmissionDate
            ? moment(params.row.lastTransmissionDate).format('DD/MM/YYYY')
            : ''
        }`,
    },
    {
      field: 'col7',
      headerName: 'Historique facture(s)',
      width: 170,
      sortable: false,
      renderCell: (params) => (
        <HtmlTooltip
          title={
            <div
              style={{
                color: 'gray',
                backgroundColor: 'white',
                borderRadius: '8px',
                padding: '8px',
                border: '1px solid #CCC',
                maxHeight: '400px',
                overflowY: 'scroll',
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {(params.row.lastBills as ILastBilling[]).map((b, index) => (
                  <p
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    key={index}
                  >
                    {/* Check if billStart is present, otherwise fallback to billing_date and omit billEnd */}
                    {b.billStart ? (
                      <>
                        {moment(b.billStart).format('DD-MM-YYYY')}
                        <BsChevronRight />{' '}
                        {moment(b.billEnd).format('DD-MM-YYYY')}
                      </>
                    ) : (
                      <>{moment(b.billing_date).format('DD-MM-YYYY')}</>
                    )}
                    {' | '}
                    Status: {getBillStatusTitle(b as any)}
                  </p>
                ))}
              </div>
            </div>
          }
        >
          <button
            style={{
              backgroundColor: 'white',
              width: '30px !important',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              padding: '4px 8px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            <BsFillCalendarEventFill
              style={{
                color: '#3f3f3f',
              }}
            />
          </button>
        </HtmlTooltip>
      ),
    },

    {
      field: 'col8',
      renderHeader: () => (
        <div style={{ display: 'block' }}>
          <div style={{ height: '20px' }}>
            {activeTab === 0 ? 'Date à facturer' : 'Date des soins code'}
          </div>

          <div>Code</div>
        </div>
      ),

      width: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <div>
            {params.row.billableDate
              ? `${moment(params.row.billableDate).format('DD/MM/YYYY')}`
              : ''}
            <br />
            {params.row.code}
          </div>
        </>
      ),
    },
    ...(canViewPatientInfos
      ? [
          {
            field: 'col9',
            headerName: 'Action',
            width: 200,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <>
                  <Tooltip title="Voir le patient">
                    <Button
                      onClick={() =>
                        handlePatientInfoByID(params.row.patientId)
                      }
                      size="small"
                      color="primary"
                      aria-label="info"
                      variant="contained"
                      style={{ marginRight: '4px', backgroundColor: '#84c2ce' }}
                    >
                      <AiFillEye
                        style={{ fontWeight: 'bold', fontSize: '24px' }}
                      />
                    </Button>
                  </Tooltip>
                  {!params.row.billingDetails ? (
                    params.row.status === BillingStatus.PENDING ? (
                      <Tooltip title="Créer une facture">
                        {params.row.billable ? (
                          <Button
                            onClick={() => handleCreateBill(params.row)}
                            size="small"
                            color="primary"
                            aria-label="info"
                            variant="contained"
                            style={{
                              backgroundColor: params.row.billable
                                ? '#ffa726'
                                : 'lightgray',
                              cursor: params.row.billable
                                ? 'pointer'
                                : 'not-allowed',
                            }}
                            disabled={!params.row.billable}
                          >
                            <AiOutlineEuroCircle
                              style={{ fontWeight: 'bold', fontSize: '24px' }}
                            />
                          </Button>
                        ) : (
                          <HtmlTooltip
                            title={
                              <div
                                style={{
                                  backgroundColor: '#90D4E1',
                                  borderRadius: '3px',
                                  padding: '4px',
                                }}
                              >
                                <ul
                                  style={{
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {getNotBillableReason(params.row).map(
                                    (reason) => (
                                      <li>{reason}</li>
                                    ),
                                  )}
                                </ul>
                              </div>
                            }
                          >
                            <Button
                              size="small"
                              color="primary"
                              variant="contained"
                            >
                              !
                            </Button>
                          </HtmlTooltip>
                        )}
                      </Tooltip>
                    ) : (
                      <> </>
                    )
                  ) : (
                    <Tooltip title="Voir la facture">
                      <Button
                        onClick={() => {
                          setBillingDetails({
                            ...params.row.billingDetails,
                            billingDate:
                              params.row.billingDetails.date_des_soins,
                          })
                        }}
                        size="small"
                        color="primary"
                        aria-label="info"
                        variant="contained"
                        style={{
                          backgroundColor:
                            activeTab === 0
                              ? '#ffa726'
                              : getBillStatusColor(
                                  params.row.billingDetails.etat_facture,
                                ),
                          marginLeft: 2,
                        }}
                      >
                        <AiOutlineEuroCircle
                          style={{ fontWeight: 'bold', fontSize: '24px' }}
                        />
                      </Button>
                    </Tooltip>
                  )}
                </>
              )
            },
          },
        ]
      : []),
  ]
  return (
    <>
      <Box
        sx={{
          height: 'calc(100vh - 240px)',
          width: '100%',
        }}
      >
        <Loader open={loading} text="En attente" />
        <Tabs
          TabIndicatorProps={{
            style: {
              backgroundColor: '#ffa726',
              display: 'flexWrap',
            },
          }}
          onChange={handleTabChange}
          textColor="primary"
          value={activeTab}
        >
          <Tab
            label={`A FACTURER: ${
              loading ? 0 : props.billableSummary?.lateBilling
            }`}
            {...a11yProps(0)}
            wrapped={false}
          />
          <Tab
            label={`FACTURÉS: ${props.billableSummary?.billedCount}`}
            {...a11yProps(1)}
            wrapped={false}
          />
          <Tab
            label={`REJETÉS: ${props.billableSummary?.rejectedBillsCount}`}
            {...a11yProps(2)}
            wrapped={false}
          />
          {/* <Tab
          label={`Calendrier de facturation`}
          />*/}
        </Tabs>

        <DataGridPro
          rows={data}
          columns={columns}
          components={{
            Toolbar,
          }}
          pagination={false}
          // onRowClick={handlePatientInfoByID}
          // loading={loading}
          componentsProps={{
            toolbar: {
              title: 'Facturation',
            },
            pagination: false,
          }}
          pageSize={rowsPerPage}
          hideFooter
          rowsPerPageOptions={undefined}
          rowHeight={60}
          disableColumnMenu
          disableSelectionOnClick
          sx={{
            border: 'none',
            padding: '3px 15px',
          }}
        />
        <PaginationComponent
          pages={props.billablePatient?.totalPages}
          activePage={activePage}
          setActivePage={setActivePage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>
      <Dialog
        open={!!billingDetails}
        onClose={() => setBillingDetails(null)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        PaperProps={{
          style: {
            borderRadius: '20px',
          },
        }}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle
          id="dialog-title"
          style={{
            backgroundColor: '#84c2ce',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <BsFillInfoCircleFill style={{ color: '#fff' }} />
            <Typography
              variant="h6"
              component="h2"
              style={{ marginLeft: 8, color: '#fff', textAlign: 'center' }}
            >
              Détails de la facturation
            </Typography>
          </div>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setBillingDetails(null)}
            aria-label="close"
          >
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Etat
                  </TableCell>
                  <TableCell>{billingDetails?.etat_facture}</TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Numéro FSE
                  </TableCell>
                  <TableCell>{billingDetails?.numero}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Version
                  </TableCell>
                  <TableCell>
                    {moment(billingDetails?.version).format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Date des soins
                  </TableCell>
                  <TableCell>
                    {moment(
                      billingDetails?.billingDate ||
                        billingDetails?.date_des_soins,
                    ).format('DD-MM-YYYY')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Mode Sécurisation
                  </TableCell>
                  <TableCell>{billingDetails?.mode_securisation}</TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Montant total
                  </TableCell>
                  <TableCell>
                    {billingDetails?.billing_amount?.total_amount
                      ? (
                          billingDetails.billing_amount.total_amount / 100
                        ).toFixed(2) + '€'
                      : '0€'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Part AMC
                  </TableCell>
                  <TableCell>
                    {billingDetails?.billing_amount?.partAmc
                      ? (billingDetails.billing_amount.partAmc / 100).toFixed(
                          2,
                        ) + '€'
                      : '0€'}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Part AMC Payée
                  </TableCell>
                  <TableCell>
                    {billingDetails?.billing_amount?.partAmcPayee
                      ? (
                          billingDetails.billing_amount.partAmcPayee / 100
                        ).toFixed(2) + '€'
                      : '0€'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Part Patient
                  </TableCell>
                  <TableCell>
                    {billingDetails?.billing_amount?.partPatient
                      ? (
                          billingDetails.billing_amount.partPatient / 100
                        ).toFixed(2) + '€'
                      : '0€'}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Part Patient Payée
                  </TableCell>
                  <TableCell>
                    {billingDetails?.billing_amount?.partPatientPayee
                      ? (
                          billingDetails.billing_amount.partPatientPayee / 100
                        ).toFixed(2) + '€'
                      : '0€'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Part AMO
                  </TableCell>
                  <TableCell>
                    {billingDetails?.billing_amount?.partAmo
                      ? (billingDetails.billing_amount.partAmo / 100).toFixed(
                          2,
                        ) + '€'
                      : ''}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Part Patient AMO Payee
                  </TableCell>
                  <TableCell>
                    {billingDetails?.billing_amount?.partAmoPayee
                      ? (
                          billingDetails.billing_amount.partAmoPayee / 100
                        ).toFixed(2) + '€'
                      : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Parcours
                  </TableCell>
                  <TableCell>
                    {billingDetails?.prise_en_charge?.parcours}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#ffa726',
                      fontWeight: 'bold',
                      color: '#fff',
                      textTransform: 'uppercase',
                    }}
                  >
                    Nature Assurance
                  </TableCell>
                  <TableCell>
                    {billingDetails?.prise_en_charge?.nature_assurance}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default BillingComponent
