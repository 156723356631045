import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { RootState } from 'src/store'
import {
  DataGridPro,
  GridColumns,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import { Box } from '@mui/material'
import { getCenterType } from '../../../../utils/addPatient'
import { ICompany } from '../../../../modules/center/reducers'
import PaginationComponent from '../../../../modules/common/PaginationComponent'
import { ToolbarInput } from '../../../../utils/ToolbarInput'
import Loader from '../../../../modules/common/Loader'
import { useLocation } from 'react-router-dom'

interface IProps {
  handleReceiverSelection: (mail: string) => void
  getCenters: (
    size: number,
    page: number,
    searchText: string,
    isImplantCenter: boolean,
  ) => Promise<unknown>
}

const CentersGrid = (props: IProps) => {
  const location = useLocation()
  const q = useRef(new URLSearchParams(location.search)).current

  const centers: { data: ICompany[]; totalPages: number } = useSelector(
    (state: RootState) =>
      state.centersReducer.centers
        ? state.centersReducer.centers
        : { data: [], totalPages: 0 },
  )
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState(
    q.has('limit')
      ? parseInt(q.get('limit') as string)
      : parseInt(localStorage.getItem('rowsPerPage') as string),
  )
  const [activePage, setActivePage] = useState(1)
  const [searchText, setSearchText] = useState('')

  const fetchDatas = async (loader: boolean) => {
    if (loader) setLoading(true)
    try {
      await props.getCenters(rows, activePage - 1, searchText, false)
    } catch (error) {
      toast.error('Impossible de charger les centres')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDatas(true)
  }, [rows, activePage]) //eslint-disable-line

  useEffect(() => {
    fetchDatas(false)
  }, [searchText]) //eslint-disable-line

  const columns: GridColumns = [
    {
      field: 'col1',
      headerName: 'Profil',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.centerDetails.center_type ? getCenterType(params.row.centerDetails.center_type) : ''}`,
    },
    {
      field: 'col2',
      headerName: 'Nom',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.userName ? params.row.userName : ''} ${params.row.companyName ? params.row.companyName : ''}`,
    },
    {
      field: 'col3',
      headerName: 'Ville',
      flex: 1,
      minWidth: 250,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.city ? params.row.city : ''} `,
    },
    {
      field: 'col4',
      headerName: 'Téléphone',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.phone ? params.row.phone : ''}`,
    },
    {
      field: 'col5',
      headerName: 'Email',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.centerDetails.emailContact ? params.row.centerDetails.emailContact : ''}`,
    },
    {
      field: 'col6',
      headerName: 'FINESS',
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.centerDetails.finess ? params.row.centerDetails.finess : ''}`,
    },
  ]

  return (
    <Box
      sx={{ padding: '3px 15px', height: 'calc(100% - 100px)', width: '100%' }}
    >
      <Loader open={loading} text="Chargement des centes" />
      <Box
        sx={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'end',
          background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
          borderRadius: '15px 15px 0px 0px',
        }}
      >
        <ToolbarInput searchText={searchText} setSearchText={setSearchText} />
      </Box>
      <DataGridPro
        rows={centers.data}
        columns={columns}
        onRowClick={(selected) =>
          props.handleReceiverSelection(
            selected.row.centerDetails.email_contact,
          )
        }
        rowHeight={60}
        hideFooter
        disableColumnMenu
        disableSelectionOnClick
        sx={{
          border: 'none',
          cursor: 'pointer',
          height: 'calc(100% - 100px)',
        }}
      />
      <PaginationComponent
        pages={centers.totalPages}
        activePage={activePage}
        setActivePage={setActivePage}
        rowsPerPage={rows}
        setRowsPerPage={setRows}
      />
    </Box>
  )
}

export default CentersGrid
